import React, { useState, useEffect } from 'react';
import data from './data.json';
import { Helmet } from 'react-helmet'; // Add this import
import seedrandom from 'seedrandom';

function Game() {
    const [gameData, setGameData] = useState([]);
    const [difficulty, setDifficulty] = useState('Medium');
    const [unplayedGames, setUnplayedGames] = useState([]);
  const [currentGame, setCurrentGame] = useState(null);
  const [currentGuess, setCurrentGuess] = useState([]);
  const [foundGroups, setFoundGroups] = useState({});
  const [attempts, setAttempts] = useState(4);
  const [message, setMessage] = useState('');
  const [animation, setAnimation] = useState(null);
  const [animationWords, setAnimationWords] = useState([]);
  const [gameOver, setGameOver] = useState(false);
  const [gameId, setGameId] = useState(null); // Add this line
  const [timeElapsed, setTimeElapsed] = useState(0);
  const [selectionStreak, setSelectionStreak] = useState([]);
  const [isArchive, setIsArchive] = useState(false)

  const levelColors = ['#fbd400', '#b5e352', '#729eeb', '#bc70c4'];
  const levelEmojis = ["🔵", "🟢", "🟡", "🔴", "🟣"]; // Choose your own emojis


  useEffect(() => {
    setTimeElapsed(0);  // Reset the timer when starting a new game
  
    // Other codes
  
    const timer = setInterval(() => {
      setTimeElapsed(timeElapsed => timeElapsed + 1);
    }, 1000);
  
    return () => clearInterval(timer);
  }, [gameId]);  // Only depend on gameId

  function mixGroups(gameId, difficulty) {
    const rng = seedrandom(gameId);
  let newGame = { groups: {}, startingGroups: [[], [], [], []] };
  let usedWords = new Set();

  // Define difficulty levels
  let levels;
  switch (difficulty) {
    case "Easy":
      levels = [0, 1];
      break;
    case "Hard":
      levels = [2, 3];
      break;
    default: // Medium
      levels = [0, 1, 2, 3];
      break;
  }

  // For "Easy" and "Hard" difficulties, repeat the levels to fill all 4 groups
  if (difficulty === "Easy" || difficulty === "Hard") {
    levels = [...levels, ...levels];
  }

  // For "Medium" difficulty, shuffle the levels to ensure each one is used
  if (difficulty === "Medium") {
    levels = levels.sort(() => 0.5 - rng());
  }

  for (let i = 0; i < 4; i++) {
    let randomId, randomGame, groupKey;

    // Keep trying until a suitable group is found
    do {
      do {
        randomId = Math.floor(rng() * data.length);
      } while (randomId === gameId);

      randomGame = data[randomId];

      const groupKeys = Object.keys(randomGame.groups);
      for (const key of groupKeys) {
        if (randomGame.groups[key].level === levels[i] &&
            !randomGame.groups[key].members.some(member => usedWords.has(member))) {
          groupKey = key;
          break;
        }
      }

    } while (!groupKey);

    newGame.groups = { ...newGame.groups, [groupKey]: randomGame.groups[groupKey] };

    // Shuffle members into startingGroups
    randomGame.groups[groupKey].members.forEach((member, index) => {
      usedWords.add(member);

      let insertionRow;
      do {
        insertionRow = Math.floor(rng() * 4);
      } while (newGame.startingGroups[insertionRow].length === 4);
      newGame.startingGroups[insertionRow].push(member);
    });
  }

  return newGame;
  }
  
  const shuffleArray = (array) => {
    let currentIndex = array.length, temporaryValue, randomIndex;
    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  };
  
  

  useEffect(() => {
    setGameData(data);
    setTimeElapsed(0);
    setUnplayedGames([...Array(data.length).keys()]);
    let gameIndex;

    const urlParams = new URLSearchParams(window.location.search);
    const gameId = urlParams.get('gameId');
    const archive = urlParams.get('archive');
    setGameId(gameId);

    const difficulty = urlParams.get('difficulty') || 'Medium'; // default to Medium
    setDifficulty(difficulty);

    if(gameId){
        gameIndex = gameId;
        const mixedGame = mixGroups(gameIndex, difficulty);
        setCurrentGame(mixedGame);
    } else {
        if( archive ){
          setGameId(1)
          setIsArchive(true)
          gameIndex = generateIndexConnections(archive) % data.length;
        }else{
          gameIndex = generateIndexConnections() % data.length;
          
        }
        setCurrentGame(data[gameIndex]);
    }

    setUnplayedGames(prevUnplayedGames => prevUnplayedGames.filter(index => index !== gameIndex));
    // Reset the timer and selection streak when a new game is started
    setSelectionStreak([]);
  }, [gameId]);

  const changeDifficulty = (newDifficulty) => {
    // Generate new random index
    const randomIndex = Math.floor(Math.random() * unplayedGames.length);
    const nextGameIndex = unplayedGames[randomIndex];
    let final = nextGameIndex;
    if( gameId )
      final = gameId
    // Update the URL to include new gameId and difficulty
    window.location.href = window.location.origin + '?gameId=' + final + '&difficulty=' + newDifficulty;
  }

  const changeArchive = (newArchive) => {
    const formattedDate = `${newArchive.getMonth()+1}/${newArchive.getDate()}/${newArchive.getFullYear()}`;
    window.location.href = `${window.location.origin}?archive=${formattedDate}`;
  }

  // Update the URL to include new archive date
  

  function getDatesBetween(start, end) {
    let startDate = new Date(start);
    let endDate = new Date(end);
    let dates = [];
    while(startDate <= endDate) {
        dates.push(new Date(startDate));
        startDate.setDate(startDate.getDate() + 1);
    }
    return dates.reverse(); // reverse to get from today back to 6/12/2023
}

  function generateIndexConnections(archive) {
    const D = 864e5;
    const e = new Date("6/12/2023");
    let t = false;
    let today = new Date();
    today.setHours(0, 0, 0, 0);
  
    if( archive ) {
      let archiveDate = new Date(archive);
      archiveDate.setHours(0, 0, 0, 0);

      // If the archive date is after today's date, throw an error
      if( archiveDate.getTime() > today.getTime() ) {
        t = today - e.setHours(0, 0, 0, 0);
      }
      else {
        t = archiveDate - e.setHours(0, 0, 0, 0);
      }
    }
    else {
      t = today - e.setHours(0, 0, 0, 0);
    }
    
    let n = Math.round(t / D);
    return n < 0 ? Math.abs(n) : n;
  }

  const handleWordClick = word => {
    // If the word is already selected, remove it from the currentGuess
    if (currentGuess.includes(word)) {
      setCurrentGuess(currentGuess.filter(guess => guess !== word));
    } 
    // Else if there are less than 4 items in the currentGuess, add the word
    else if (currentGuess.length < 4) {
      setCurrentGuess([...currentGuess, word]);
    } 
    // If there are 4 items already and the word isn't one of them, ignore the click
    else {
      return;
    }
  };

  
  

  const submitGuess = () => {
    if (currentGuess.length !== 4) {
        setMessage('You need to select 4 items before submitting');
        return;
      }
      console.log(currentGame)
      const guessedGroup = Object.entries(currentGame.groups).find(
        ([groupName, group]) =>
          JSON.stringify(group.members.sort()) === JSON.stringify(currentGuess.sort())
      );

    if (guessedGroup) {
      setFoundGroups({
        ...foundGroups,
        [guessedGroup[0]]: guessedGroup[1].members,
      });

      const newStartingGroups = currentGame.startingGroups
        .flat()
        .filter(word => !currentGuess.includes(word));

      setCurrentGame({
        ...currentGame,
        startingGroups: chunkArray(newStartingGroups, 4),
      });
      
      setMessage('Congratulations! You have found a group!');
        setAnimation('success');
        setAnimationWords(currentGuess);
        setTimeout(() => {
            setCurrentGuess([]);
            setAnimation(null);
            setAnimationWords([]);
        }, 1000);
    } else {
        setAnimation('error');
        setAnimationWords(currentGuess);
        setTimeout(() => {
            setAnimation(null);
            setAnimationWords([]);
        }, 1000);
      setMessage('Incorrect guess, please try again');
        setAttempts(prevAttempts => {
            if(prevAttempts - 1 === 0) {
                setGameOver(true);
                setMessage('Game Over! All attempts used up!');
            }
            return prevAttempts - 1;
        });
    }

    const wordLevels = currentGuess.map((word) => {
      // Find the group to which the word belongs
      const groupName = Object.keys(currentGame.groups).find(
        key => currentGame.groups[key].members.includes(word)
      );
    
      // Get the level of this group
      const level = currentGame.groups[groupName].level;
    
      // Return the word and its corresponding level
      return { word, level };
    });
  
    setSelectionStreak([...selectionStreak, wordLevels]);
  };

  const startNewGame = () => {
    if (unplayedGames.length === 0) {
      setMessage('No more games left!');
      return;
    }

    const randomIndex = Math.floor(Math.random() * 500);

    // Redirect to new page with gameId as URL parameter
    window.location.href = window.location.origin + '?gameId=' + randomIndex;
  };

  const restartGame = () => {
    window.location.reload();
  };

  const resetGuess = () => {
    setCurrentGuess([]);
  };

  const copyResultsToClipboard = () => {
    // Create a string from the selection streak
    const resultsString = selectionStreak.map((guess, index) => 
      guess.map((wordObj) => `${levelEmojis[wordObj.level]}`).join('')
    ).join('');
    window.focus();
    // Copy the string to the clipboard
    navigator.clipboard.writeText('categoriesgame.org - ' + resultsString).then(function(){
      alert('Copied to Clipboard, you can now share the result in social media.');
    });
    // Show an alert message
  };

  
  const archiveDates = getDatesBetween("6/12/2023", new Date())

  return (
    <>
    { /*<div className="ad-bar">Try this <a href="https://www.nflgrid.org/" target="_blank">Play NFL Grid Wordle!</a> 🎮</div> */ }
    
    
    
      {gameId && (
        <Helmet>
          <meta name="robots" content="noindex" />
        </Helmet>
      )}
        
       
        {Object.entries(foundGroups).map(([groupName, words], index) => (
        <div
          className="game-group"
          key={groupName}
          style={{ backgroundColor: levelColors[index % levelColors.length] }}
        >
          <h3 className="group-name">{groupName}</h3>
          <div className="group-members">{words.join(', ')}</div>
        </div>
      ))}

<div className="top-g">
  <p className="p-text">{ gameId && !isArchive && <strong>Categories #{gameId} { difficulty && <>{difficulty}</> }</strong> }{ !gameId || isArchive && <span>Create four groups of four.</span>}</p>
  <div className="game-time">Time: {`${Math.floor(timeElapsed / 60).toString().padStart(2, '0')}:${(timeElapsed % 60).toString().padStart(2, '0')}`}</div>
</div>
      

      {currentGame && currentGame.startingGroups.map((group, groupIndex) => (
        <div className="game-board" key={groupIndex}>
          {group.map((word, wordIndex) => (
            <button
            className={`game-item 
              ${currentGuess.includes(word) ? 'selected' : ''} 
              ${animationWords.includes(word) ? `${animation}-animation` : ''} 
              ${word.length === 8 ? 'size-8' : ''} 
              ${word.length > 8 ? 'size-more' : ''}`}
            key={wordIndex}
            onClick={() => handleWordClick(word)}
          >
            {word || ' '}
          </button>
          
          ))}
        </div>
      ))}
      {message && <div className="message">{message}</div>}
      <div className="game-attempts">Mistakes remaining: {attempts}</div>
      {gameOver ? (
        <>
          <button className="game-btn" onClick={restartGame}>Restart the same Game</button>
          <a 
          className="game-btn" 
          href="#" 
          onClick={(e) => {
            e.preventDefault();
            startNewGame();
          }}
          role="button"
          aria-pressed="false">Start New Game</a>
        </>
      ) : (
        <>
          {currentGame && Object.keys(foundGroups).length === Object.keys(currentGame.groups).length ? (
            <>
                <div className="congratulations">
                    <h2>Congratulations!</h2>
                    <p>You have found all groups!</p>
                    <div className="share">
  {selectionStreak.map((guess, index) => (
    <div key={index} className="share-row">
      {guess.map((wordObj, i) => (
        <span key={i} style={{ backgroundColor: levelColors[wordObj.level] }}>
          
        </span>
      ))}
    </div>
  ))}
</div>
<button className="game-btn" style={{ backgroundColor:'#000000', color:'#fff', marginTop:'10px' }} onClick={copyResultsToClipboard}>Share Results</button>
                </div>
                <a 
                  className="game-btn" 
                  href="#" 
                  onClick={(e) => {
                    e.preventDefault();
                    startNewGame();
                  }}
                  role="button"
                  aria-pressed="false">Start New Game</a>
            </>
        
          ) : (
            <div className="btn-wrapper">
              <button className="game-btn submit-btn" onClick={submitGuess}>Submit</button>
              <button className="game-btn" onClick={resetGuess}>Deselect</button>
              <a 
                className="game-btn" 
                href="#" 
                onClick={(e) => {
                  e.preventDefault();
                  startNewGame();
                }}
                role="button"
                aria-pressed="false">New Game</a>
            </div>
          )}
          
          <div className="difficulty-wrapper">
            <button onClick={() => changeDifficulty('Easy')} className={difficulty === 'Easy' ? 'selected' : ''}>Easy</button>
            <button onClick={() => changeDifficulty('Medium')} className={difficulty === 'Medium' ? 'selected' : ''}>Medium</button>
            <button onClick={() => changeDifficulty('Hard')} className={difficulty === 'Hard' ? 'selected' : ''}>Hard</button>
          </div>

          
            
        </>
      )}
      
    
    </>
  );
}

function chunkArray(array, size) {
  const result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
}

export default Game;